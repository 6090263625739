var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{scopedSlots:_vm._u([{key:"code",fn:function(){return [_vm._v(" "+_vm._s(_vm.codeBasic)+" ")]},proxy:true}])},[_c('b-row',{staticClass:"d-none d-md-flex"},[_c('b-col',{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1),_c('b-form-input',{attrs:{"placeholder":_vm.$t('Search')}})],1),_c('b-button',{staticClass:"search-btn mr-0",attrs:{"variant":"none"}},[_c('span',[_vm._v(_vm._s(_vm.$t("GO")))])])],1)])],1),_c('b-col',{staticClass:"alerts-show",attrs:{"cols":"12","md":"6","xl":"8"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("Show")]),_c('v-select',{staticClass:"mx-",attrs:{"options":['3', '5', '10'],"searchable":false},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}})],1)])],1)],1),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"rtl":_vm.direction,"select-options":{
      enabled: true,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    },"pagination-options":{
      enabled: true,
      perPage: _vm.pageLength,
      nextLabel: _vm.$t('Next'),
      prevLabel: _vm.$t('Prev'),
      rowsPerPageLabel: _vm.$t('Rows per page'),
      ofLabel: _vm.$t('of'),
    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'example1')?_c('span',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.example1))])]):(props.column.field === 'example2')?_c('span',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.example2))])]):(props.column.field === 'example3')?_c('span',[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.example3))])]):(props.column.field === 'example5')?_c('span',{staticClass:"text-nowrap"},[_c('b-badge',{attrs:{"variant":_vm.statusVariant(props.row.example5)}},[_vm._v(" "+_vm._s(props.row.example5)+" ")])],1):_vm._e()]}}])},[_c('template',{slot:"pagination-bottom"},[_c('div',{staticClass:"d-flex justify-content-center flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Showing 1 to 7 ")]),_c('span',{staticClass:"text-nowrap"},[_vm._v(" of 100 entries ")])]),_c('div',{staticClass:"d-flex flex-wrap"},[_c('b-pagination',{staticClass:"pagination-btn mb-0",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }